


*{
    box-sizing: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Nunito, Arial, Helvetica Neue, Helvetica, sans-serif

}

body{
    font-family: Nunito, Arial, Helvetica Neue, Helvetica, sans-serif

}
@media (max-width: 600px) {
    .Panel{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        position:absolute;

       
    }

    .PanelVideo{
        height:100%;
        width:100%;
    }
    .Video{
        width:100%;
        height:50%;
        position:relative
    }

  }

 

  @media (max-width: 900px) {
  
    .Panel{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        position:absolute;
        
    }
    .PanelVideo{
        height:100%;
        width:100%;
    }
    .Video{
        width:100%;
        height:50%;
        position:relative
    }
  }

  @media (min-width: 900px) {
   

    .Panel{
        display: flex;
        width:100%;
        height:100%;
        position:absolute;
       
    }
   

    .PanelVideo{
       height:100%;
       width:30%;
    }
    .Video{
        width:100%;
        height:100%;
        position:relative
    }
    
  }